<template>
  <ValidationObserver v-if="isTabs" v-slot="{ valid }">
    <div class="tabs">
      <v-tabs v-model="tab" color="#1E1E22">
        <v-tabs-slider color="#1E1E22"></v-tabs-slider>

        <v-tab v-for="tabItem in tabs" :key="tabItem.value" :disabled="tabItem.disabled">
          {{ $t(tabItem.label) }}
        </v-tab>
      </v-tabs>
    </div>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="tabItem in tabs" :key="tabItem.value" :transition="false">
        <v-form v-model="isValid" @submit.prevent="handleSubmit">
          <FormItem
            v-for="item in filteredSchema"
            v-show="isShow(item)"
            :key="item.prop"
            :class="{ 'mini-width': item.type === 'enum' }"
            v-bind="item"
            :data="data[item.prop]"
            :prop-path="getPropertyPath(item)"
          />
          <slot name="footer" :valid="valid" :reset="reset" />
        </v-form>
      </v-tab-item>
    </v-tabs-items>
  </ValidationObserver>

  <ValidationObserver v-else v-slot="{ valid }">
    <v-form v-model="isValid" @submit.prevent="handleSubmit">
      <FormItem
        v-for="item in filteredSchema"
        v-show="isShow(item)"
        :key="item.prop"
        :class="{ 'mini-width': item.type === 'enum' }"
        v-bind="item"
        :data="data[item.prop]"
        :prop-path="getPropertyPath(item)"
      />

      <slot name="footer" :valid="valid" :reset="reset" />
    </v-form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { PATH_POINTERS } from '@/constants/builder';
import { mergeIfPropertyEmpty, isEqual, isEmpty } from '@/utils/common';

import { createModelData, initializePropertyFactory } from '@/schemas/createModelData';
import * as onUpdateEffects from '@/schemas/onUpdateEffects';
import { UNIQUE_KEY } from '@/schemas/config';

import * as builderTypes from '@/constants/builder';
import * as valueTypes from '@/constants/value';
import { COMPANY_SECTIONS_RIGHTS } from '@/store/modules/user/types';

import { clone } from '@/utils/clone';
import { getSchemaPropertyByPath, getPropertyByPath } from '@/utils/schema';
import { excludeKeys } from '@/utils/excludeKeys';
import {
  fetchBuildings,
  fetchProjects,
  fetchUnits,
  fetchRooms,
  fetchCategories,
  fetchRequests,
  fetchManyProjects,
  fetchManyServiceTypes,
  fetchContractors,
  fetchManyUnits,
  fetchManyRooms,
  fetchManyBuildings,
  fetchClients,
  fetchRoles,
  fetchManyRoles,
  fetchManyPaymentType,
  fetchEmployees,
  fetchManyRequestsTypes,
  fetchRequestsTypes,
  fetchRequestsCategories,
  fetchRequestsCategoriesIcons,
} from '@/services/select';
import * as schemaItemTypes from '@/schemas/schemaItemTypes';

import SERIALIZATIONS_MAP from '@/constants/serializersMap';

import comparatorsMap from '@/utils/comparatorsMap';
import jsep from 'jsep';
import { evaluateBooleanExpressionTree } from '@/utils/expression';

import FormItem from './FormItem.vue';

const DICTIONARY_MAP = {
  [schemaItemTypes.PROJECT]: fetchProjects,
  [schemaItemTypes.BUILDING]: fetchBuildings,
  [schemaItemTypes.UNIT]: fetchUnits,
  [schemaItemTypes.ROOM]: fetchRooms,
  [schemaItemTypes.CATEGORY]: fetchCategories,
  [schemaItemTypes.REQUEST]: fetchRequests,
  [schemaItemTypes.MANY_PROJECTS]: fetchManyProjects,
  [schemaItemTypes.MANY_SERVICE_TYPES]: fetchManyServiceTypes,
  [schemaItemTypes.MANY_CONTRACTORS_TYPES]: fetchContractors,
  [schemaItemTypes.MANY_UNITS]: fetchManyUnits,
  [schemaItemTypes.MANY_ROOMS]: fetchManyRooms,
  [schemaItemTypes.MANY_BUILDINGS]: fetchManyBuildings,
  [schemaItemTypes.MANY_PAYMENT_TYPE]: fetchManyPaymentType,
  [schemaItemTypes.CLIENT]: fetchClients,
  [schemaItemTypes.ROLE]: fetchRoles,
  [schemaItemTypes.MANY_ROLES]: fetchManyRoles,
  [schemaItemTypes.EMPLOYEES]: fetchEmployees,
  [schemaItemTypes.REQUEST_TYPE]: fetchRequestsTypes,
  [schemaItemTypes.REQUEST_CATEGORY]: fetchRequestsCategories,
  [schemaItemTypes.REQUEST_ICON]: fetchRequestsCategoriesIcons,
  [schemaItemTypes.REQUEST_MANY_TYPES]: fetchManyRequestsTypes,
};

export default {
  name: 'SchemaFormBuilder',

  components: {
    ValidationObserver,
    FormItem,
  },

  provide() {
    return {
      getPropertyValue: this.getPropertyValue,
      setPropertyValue: this.setPropertyValue,
      buildPropPath: this.buildPropPath,
      getPayloadForItem: this.getPayloadForItem,
      filterSchema: this.filterSchema,
      globalConfig: this.globalConfig,
      computeDisplayConditions: this.computeDisplayConditions,

      // for arrays
      addPropertyValueItem: this.addPropertyValueItem,
      deletePropertyValueItem: this.deletePropertyValueItem,
    };
  },

  props: {
    schema: {
      type: Array,
      default: () => [],
    },
    initialData: {
      type: Object,
      default: () => ({}),
    },
    removeLabelSuffix: {
      type: Boolean,
      default: false,
    },
    enableCache: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    isTabs: {
      type: Boolean,
      default: false,
    },
    activeTab: {
      type: Number,
      default: 0,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isValid: false,
      data: clone(this.initialData),
      manuallyEnteredData: this.enableCache ? createModelData(this.schema) : {},
      globalConfig: {
        removeLabelSuffix: this.removeLabelSuffix,
      },
    };
  },

  computed: {
    filteredSchema() {
      return this.filterSchema(this.schema);
    },
    tab: {
      get() {
        this.$emit('save-data', excludeKeys(clone(this.data), UNIQUE_KEY, true));
        return this.activeTab;
      },
      set(newTab) {
        this.$emit('change-tab', newTab);
      },
    },
    ...mapGetters('user', {
      companySections: COMPANY_SECTIONS_RIGHTS,
    }),
  },

  watch: {
    removeLabelSuffix(newValue) {
      this.globalConfig.removeLabelSuffix = newValue;
    },

    initialData(newValue) {
      this.data = clone(newValue);
    },

    value(newValue) {
      this.data = clone(newValue);
    },

    schema: {
      handler() {
        this.manuallyEnteredData = this.enableCache ? createModelData(this.schema) : {};
      },
      deep: true,
    },
  },

  methods: {
    filterSchema(schema) {
      return schema.filter(item => {
        if (
          item?.visibility?.find(
            visibleItem => visibleItem.builderType === builderTypes.FORM && visibleItem.value === valueTypes.NONE
          )
        ) {
          return false;
        }

        // @todo Реализовать определение логики вычисления
        if (item.displayConditions) {
          return this.computeDisplayConditions(item.displayConditions);
        }

        if (item.access) {
          return this.computeAccess(item.access);
        }

        return true;
      });
    },

    handleSubmit() {
      this.$emit('submit', excludeKeys(clone(this.data), UNIQUE_KEY, true));
    },

    getPayloadForItem(item, propPath) {
      return this.getPayload(this.data, item, propPath);
    },

    getPayload(data, item, propPath, isEffect = false) {
      return item?.payload?.reduce((payload, { param, from, serializer, cache, onlyEffect }) => {
        if (onlyEffect && !isEffect) return payload;

        const buildFromPath = this.buildPropPath(from, propPath);

        let value =
          this.enableCache && cache
            ? this.getCachePropertyByPath({ propPath: buildFromPath })
            : getPropertyByPath(data, buildFromPath);

        if (value !== undefined) {
          if (serializer) {
            value = SERIALIZATIONS_MAP[serializer](value);
          }

          return { ...payload, [param]: value };
        }

        return payload;
      }, {});
    },

    buildPropPath(propPaths, ownerPropPaths) {
      return propPaths.map((propPath, index) => {
        if (propPath === PATH_POINTERS.currentIndex) {
          return ownerPropPaths[index];
        }

        return propPath;
      });
    },

    async runDependentPropertyEffect({ effect, sourceValue, ignoreProperties, exciter, updatedData }) {
      const propPath = Array.isArray(effect.to) ? this.buildPropPath(effect.to, exciter) : [effect.to];

      switch (effect.type) {
        case onUpdateEffects.SET: {
          if (!sourceValue) return;
          const { from } = effect;

          const value = sourceValue[from];

          await this.setPropertyValue({ propPath, value, ignoreProperties, updatedData });
          break;
        }
        case onUpdateEffects.REFILL: {
          const currentValue = this.getPropertyValue({ propPath });

          if (!currentValue) return;

          const schemaItem = getSchemaPropertyByPath(this.schema, propPath);
          const payload = this.getPayload(updatedData, schemaItem, propPath);
          const dataPayload = this.getPayload(this.data, schemaItem, propPath);

          const fetch = DICTIONARY_MAP[schemaItem.type];

          if (fetch) {
            const list = await fetch(mergeIfPropertyEmpty(payload, dataPayload));
            const propertyValue = this.getPropertyValue({ propPath });

            const matchingIdIndex = list?.results?.findIndex?.(item => item?.id === propertyValue?.id);

            const hasMatchingId = matchingIdIndex !== -1;

            if (hasMatchingId) {
              break;
            } else if (list.results.length === 1) {
              await this.setPropertyValue({ propPath, value: list.results[0], ignoreProperties, updatedData });
            } else {
              const prevValue = this.getPropertyValue({ propPath });

              if (prevValue) {
                const schemaProperty = getSchemaPropertyByPath(this.schema, propPath);
                const factory = initializePropertyFactory(schemaProperty);
                const value = factory();
                await this.setPropertyValue({ propPath, value, ignoreProperties, updatedData, setToCache: true });
              }
            }
          }
          break;
        }
        case onUpdateEffects.RESET: {
          const prevValue = this.getPropertyValue({ propPath });

          if (prevValue) {
            const schemaProperty = getSchemaPropertyByPath(this.schema, propPath);
            const factory = initializePropertyFactory(schemaProperty);
            const value = factory();
            await this.setPropertyValue({ propPath, value, ignoreProperties, updatedData, setToCache: true });
          }
          break;
        }
        case onUpdateEffects.SET_IF_PROPERTY_TRUE: {
          const { conditionProp, from } = effect;

          if (sourceValue[conditionProp]) {
            const value = sourceValue[from];

            await this.setPropertyValue({ propPath, value, ignoreProperties, updatedData });
          }

          break;
        }
        case onUpdateEffects.RESET_OR_SET_IF_ONE: {
          const schemaItem = getSchemaPropertyByPath(this.schema, propPath);
          const payload = this.getPayload(updatedData, schemaItem, propPath);
          const dataPayload = this.getPayload(this.data, schemaItem, propPath);

          if (!schemaItem) break;

          const fetch = DICTIONARY_MAP[schemaItem.type];

          if (fetch) {
            const list = await fetch(mergeIfPropertyEmpty(payload, dataPayload));
            const propertyValue = this.getPropertyValue({ propPath });

            const matchingIdIndex = list?.results?.findIndex?.(item => item?.id === propertyValue?.id);

            const hasMatchingId = matchingIdIndex !== -1;

            if (hasMatchingId) {
              break;
            } else if (list.results.length === 1) {
              await this.setPropertyValue({ propPath, value: list.results[0], ignoreProperties, updatedData });
            } else {
              const prevValue = this.getPropertyValue({ propPath });

              if (prevValue) {
                const schemaProperty = getSchemaPropertyByPath(this.schema, propPath);
                const factory = initializePropertyFactory(schemaProperty);
                const value = factory();
                await this.setPropertyValue({ propPath, value, ignoreProperties, updatedData, setToCache: true });
              }
            }
          }

          break;
        }
        case onUpdateEffects.RESET_SELECTED_PROPERTY: {
          const value = this.getPropertyValue({ propPath });

          if (sourceValue.find(item => item.selected)) {
            await this.setPropertyValue({
              propPath,
              value: value.map(item => ({ ...item, selected: false })),
              ignoreProperties,
              updatedData,
            });
          }

          break;
        }
        case onUpdateEffects.AUTOCOMPLETE_MANY: {
          const emptyMany = {
            all: false,
            exclude: [],
            include: [],
          };

          const schemaItem = getSchemaPropertyByPath(this.schema, propPath);
          const value = sourceValue;
          const cacheValue = this.getCachePropertyByPath({ propPath });

          if (!isEqual(cacheValue, emptyMany)) {
            break;
          }

          const payload = {
            ...this.getPayload(this.data, schemaItem, propPath, true),
            ...this.getPayload(updatedData, schemaItem, propPath, true),
          };

          // let isEmptyPayload = true;

          // // eslint-disable-next-line no-restricted-syntax
          // for (const key in payload) {
          //   if (payload[key]) {
          //     isEmptyPayload = false;
          //   }
          // }

          // if (isEmptyPayload) {
          //   break;
          // }

          const fetch = DICTIONARY_MAP[schemaItem.type];
          let results = null;

          try {
            const data = await fetch({ ...payload });
            results = data.results;
          } catch (error) {
            break;
          }

          if (value.all) {
            await this.setPropertyValue({
              propPath,
              value: {
                ...emptyMany,
                all: true,
              },
              ignoreProperties,
              updatedData,
            });
            break;
          }

          await this.setPropertyValue({
            propPath,
            value: {
              ...emptyMany,
              include: results,
            },
            ignoreProperties,
            updatedData,
          });

          break;
        }
        // eslint-disable-next-line
        default: {
          break;
        }
      }
    },

    /**
     * @param {import('@/schemas/schema').DisplayConditions} displayConditions
     */
    computeDisplayConditions(displayConditions, ownerPropPaths = []) {
      const finalValues = displayConditions.variables.reduce((result, variable) => {
        let propertyValue = null;
        if (ownerPropPaths.length) {
          propertyValue = this.getPropertyValue({ propPath: this.buildPropPath(variable.from, ownerPropPaths) });
        } else {
          propertyValue = this.getPropertyValue({ propPath: variable.from });
        }

        const propName = variable.from.at(-1);

        /** @type {import('@/schemas/schema').default} */
        const dependenceSchemaProperty = getSchemaPropertyByPath(this.schema, variable.from);

        if (dependenceSchemaProperty?.displayConditions) {
          if (!this.computeDisplayConditions(dependenceSchemaProperty.displayConditions)) {
            return { ...result, [propName]: false };
          }
        }

        let comparisonResult = false;

        if (variable.comparableValues) {
          comparisonResult = variable.comparableValues.some(value => {
            return comparatorsMap[variable.comparator](propertyValue, value);
          });
        } else if (propertyValue) {
          if (variable.hasChildren) {
            const payload = {};

            if (Array.isArray(variable.hasChildren.requestPayload)) {
              variable.hasChildren.requestPayload.forEach(p => {
                payload[p] = propertyValue[p];
              });

              return variable.hasChildren
                .request(payload)
                .then(res => {
                  comparisonResult = variable.hasChildren.prop ? !!res[variable.hasChildren.prop].length : !!res.length;
                })
                .then(() => {
                  return { ...result, [propName]: comparisonResult };
                });
            }
            return variable.hasChildren
              .request(propertyValue[variable.hasChildren.requestPayload])
              .then(res => {
                comparisonResult = variable.hasChildren.prop ? !!res[variable.hasChildren.prop].length : !!res.length;
              })
              .then(() => {
                return { ...result, [propName]: comparisonResult };
              });
          }
        }

        return { ...result, [propName]: comparisonResult };
      }, {});
      const expressionWithValues = Object.entries(finalValues).reduce((result, [propName, comparisonResult]) => {
        return result.replaceAll(propName, comparisonResult);
      }, displayConditions.expression);

      const expressionTree = jsep(expressionWithValues);
      return evaluateBooleanExpressionTree(expressionTree);
    },

    computeAccess(access) {
      for (let i = 0; i < access.length; i += 1) {
        if (!this.companySections.find(section => section.name === access[i])) {
          return false;
        }
      }
      return true;
    },

    // TODO: fix effect for not root properties
    async updateDependentProperties({ schemaProperty, sourceValue, ignoreProperties, exciter, updatedData }) {
      const { onUpdate: effects = null } = schemaProperty;

      if (Array.isArray(effects)) {
        await Promise.all(
          effects.map(async effect => {
            const isIgnoreEffect = ignoreProperties.some(ignoreProperty => {
              const prop = effect.to;

              if (Array.isArray(effect.to)) {
                return effect.to.every((localProp, index) => {
                  const currentPath = ignoreProperty.propPath[index];

                  if (localProp === PATH_POINTERS.currentIndex) {
                    return true;
                  }

                  return localProp === currentPath;
                });
              }

              return ignoreProperty.prop === prop;
            });

            // eslint-disable-next-line
            if (isIgnoreEffect) return;

            // eslint-disable-next-line
            return this.runDependentPropertyEffect({
              effect,
              sourceValue,
              ignoreProperties,
              exciter,
              schemaProperty,
              updatedData,
            });
          })
        );
      }
    },

    getPropertyValue({ propPath = [] }) {
      return getPropertyByPath(this.data, propPath);
    },

    getCachePropertyByPath({ propPath = [] }) {
      return getPropertyByPath(this.manuallyEnteredData, propPath);
    },

    async setPropertyValue({ propPath, value, ignoreProperties = [], updatedData = {}, setToCache = false }) {
      const parentPropPath = propPath.slice();
      const prop = parentPropPath.pop();

      const parentValue = getPropertyByPath(this.data, parentPropPath);

      const schemaProperty = { ...getSchemaPropertyByPath(this.filteredSchema, propPath), propPath };

      this.createPayloadData({ updatedData, propPath, value });

      await this.updateDependentProperties({
        schemaProperty,
        sourceValue: value,
        exciter: propPath,
        ignoreProperties: [...ignoreProperties, schemaProperty],
        updatedData,
      });

      parentValue[prop] = value;

      if ((ignoreProperties.length === 0 || setToCache) && !isEmpty(this.manuallyEnteredData)) {
        const manuallyEnteredParentValue = getPropertyByPath(this.manuallyEnteredData, parentPropPath);
        manuallyEnteredParentValue[prop] = value;
      }
      if (ignoreProperties.length === 0) {
        this.$emit('input', clone(this.data));
      }
    },

    createPayloadData({ updatedData, propPath, value }) {
      const parentPropPath = propPath.slice();
      const prop = parentPropPath.pop();

      const parentValue = parentPropPath.reduce(
        // eslint-disable-next-line
        (result, localProp) => (result[localProp] = result[localProp] ?? {}),
        updatedData
      );

      parentValue[prop] = value;
    },

    addPropertyValueItem({ propPath }) {
      const value = this.getPropertyValue({ propPath });
      const schemaProperty = getSchemaPropertyByPath(this.schema, propPath);
      const item = createModelData(schemaProperty.children, true);
      value.push(item);
    },

    deletePropertyValueItem({ propPath, index }) {
      const value = this.getPropertyValue({ propPath });
      value.splice(index, 1);
    },

    getPropertyPath(item) {
      return item.prop ? [item.prop] : [];
    },

    isShow(item) {
      return !item?.visibility?.find(
        visibleItem => visibleItem.builderType === builderTypes.FORM && visibleItem.value === valueTypes.HIDDEN
      );
    },

    reset() {
      this.data = createModelData(this.schema);

      if (this.enableCache) {
        this.manuallyEnteredData = createModelData(this.schema);
      }

      this.$emit('input', clone(this.data));
    },
  },
};
</script>

<style lang="scss">
.tabs {
  & + .v-window {
    padding: 10px;
    margin: -10px;
    max-width: calc(100% + 20px);
  }
  .v-tabs {
    margin-bottom: 41px;

    .v-slide-group__prev,
    .v-slide-group__next {
      display: none !important;
    }
  }

  .v-tab {
    padding: 0;
    min-width: 0;

    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    letter-spacing: normal;
    text-transform: none;

    &::before,
    &::after {
      display: none !important;
    }
  }

  .v-tab + .v-tab {
    margin-left: 24px;
  }

  .v-tabs-bar {
    height: 36px;
  }
}
</style>
