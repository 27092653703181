<template>
  <div>
    <div v-if="value">
      <p class="text-body-1 mb-2 text--secondary">{{ label }}</p>
      <div class="d-flex align-center justify-space-between">
        <component :is="selectedIcon.icon" width="70" />
        <icon-button exact :width="36" class="ml-2" @click.stop="deleteIcon">
          <v-icon color="primary">mdi-delete</v-icon>
        </icon-button>
      </div>
    </div>
    <v-menu v-else offset-y :close-on-content-click="false" max-width="531" max-height="272">
      <template #activator="{ on, attrs }">
        <div class="d-flex pb-2 mt-3">
          <span v-bind="attrs" class="d-flex align-center primary--text text-uppercase" v-on="on">
            <v-icon color="primary" class="me-4">mdi-plus</v-icon>
            {{ $t('button.add_icon') }}
            <span v-if="isRequiredField" class="ms-1">*</span>
          </span>
        </div>
      </template>
      <template>
        <v-card>
          <div class="icon-select__search-field">
            <text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              class="multi-select__search mt-0 pt-0 mb-2"
              hide-details
              :placeholder="$t(placeholderSearch)"
            />
          </div>
          <div class="d-flex flex-wrap">
            <div v-for="icon in filtredIcons" :key="icon.value" class="px-1 py-1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon large v-bind="attrs" @click="selectIcon(icon)" v-on="on">
                    <component :is="icon.icon" width="24" height="24" />
                  </v-btn>
                </template>
                <span>{{ icon.title }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-card>
      </template>
    </v-menu>
  </div>
</template>

<script>
// Utils
import { isEqual } from '@/utils/common';
import { compareById } from '@/utils/comparators';
import { createModelWrapper, getInheritedListeners } from '@/utils/components';
import { debounce } from '@/utils/delay';

import { fetchRequestsCategoriesIcons } from '@/services/select';
import TextField from '@/components/TextField.vue';
import IconButton from '@/components/IconButton.vue';

// Icons
import Rocket from '@/assets/icons/Rocket.svg';
import Plane from '@/assets/icons/Plane.svg';
import Helicopter from '@/assets/icons/Helicopter.svg';
import TrainProfile from '@/assets/icons/TrainProfile.svg';
import SailboatOffshore from '@/assets/icons/SailboatOffshore.svg';
import Harbor from '@/assets/icons/Harbor.svg';
import Car from '@/assets/icons/Car.svg';
import DeliveryTruck from '@/assets/icons/DeliveryTruck.svg';
import Bicycle from '@/assets/icons/Bicycle.svg';
import GasStation from '@/assets/icons/GasStation.svg';
import ChargingStation from '@/assets/icons/ChargingStation.svg';
import Earth from '@/assets/icons/Earth.svg';
import Location from '@/assets/icons/Location.svg';
import Enterprise from '@/assets/icons/Enterprise.svg';
import Building from '@/assets/icons/Building.svg';
import Home from '@/assets/icons/Home.svg';
import Hotel from '@/assets/icons/Hotel.svg';
import Store from '@/assets/icons/Store.svg';
import ShoppingCart from '@/assets/icons/ShoppingCart.svg';
import Events from '@/assets/icons/Events.svg';
import UserFollow from '@/assets/icons/UserFollow.svg';
import UserProfileAlt from '@/assets/icons/UserProfileAlt.svg';
import Accessibility from '@/assets/icons/Accessibility.svg';
import Finance from '@/assets/icons/Finance.svg';
import ToolBox from '@/assets/icons/ToolBox.svg';
import Wallet from '@/assets/icons/Wallet.svg';
import Money from '@/assets/icons/Money.svg';
import Purchase from '@/assets/icons/Purchase.svg';
import Receipt from '@/assets/icons/Receipt.svg';
import Scales from '@/assets/icons/Scales.svg';
import PiggyBank from '@/assets/icons/PiggyBank.svg';
import Currency from '@/assets/icons/Currency.svg';
import CurrencyBaht from '@/assets/icons/CurrencyBaht.svg';
import CurrencyDollar from '@/assets/icons/CurrencyDollar.svg';
import CurrencyEuro from '@/assets/icons/CurrencyEuro.svg';
import CurrencyRuble from '@/assets/icons/CurrencyRuble.svg';
import Percentage from '@/assets/icons/Percentage.svg';
import Bottles01 from '@/assets/icons/Bottles01.svg';
import Bar from '@/assets/icons/Bar.svg';
import Drink01 from '@/assets/icons/Drink01.svg';
import FruitBowl from '@/assets/icons/FruitBowl.svg';
import NoodleBowl from '@/assets/icons/NoodleBowl.svg';
import Apple from '@/assets/icons/Apple.svg';
import Strawberry from '@/assets/icons/Strawberry.svg';
import Fish from '@/assets/icons/Fish.svg';
import Restaurant from '@/assets/icons/Restaurant.svg';
import ServiceDesk from '@/assets/icons/ServiceDesk.svg';
import Cafe from '@/assets/icons/Cafe.svg';
import Cookie from '@/assets/icons/Cookie.svg';
import Basketball from '@/assets/icons/Basketball.svg';
import Gamification from '@/assets/icons/Gamification.svg';
import ColorPalette from '@/assets/icons/ColorPalette.svg';
import Theater from '@/assets/icons/Theater.svg';
import Music from '@/assets/icons/Music.svg';
import GameConsole from '@/assets/icons/GameConsole.svg';
import Model from '@/assets/icons/Model.svg';
import SprayPaint from '@/assets/icons/SprayPaint.svg';
import Camera from '@/assets/icons/Camera.svg';
import Gift from '@/assets/icons/Gift.svg';
import Trophy from '@/assets/icons/Trophy.svg';
import CalculationAlt from '@/assets/icons/CalculationAlt.svg';
import Cut from '@/assets/icons/Cut.svg';
import PaintBrush from '@/assets/icons/PaintBrush.svg';
import TrashCan from '@/assets/icons/TrashCan.svg';
import Chemistry from '@/assets/icons/Chemistry.svg';
import HangingProtocol from '@/assets/icons/HangingProtocol.svg';
import Binoculars from '@/assets/icons/Binoculars.svg';
import Bullhorn from '@/assets/icons/Bullhorn.svg';
import Calibrate from '@/assets/icons/Calibrate.svg';
import Microscope from '@/assets/icons/Microscope.svg';
import Stethoscope from '@/assets/icons/Stethoscope.svg';
import ToolKit from '@/assets/icons/ToolKit.svg';
import Tools from '@/assets/icons/Tools.svg';
import ToolsAlt from '@/assets/icons/ToolsAlt.svg';
import Clean from '@/assets/icons/Clean.svg';
import Box from '@/assets/icons/Box.svg';
import Email from '@/assets/icons/Email.svg';
import Folder from '@/assets/icons/Folder.svg';
import DocumentBlank from '@/assets/icons/DocumentBlank.svg';
import DocumentMultiple01 from '@/assets/icons/DocumentMultiple01.svg';
import ResultDraft from '@/assets/icons/ResultDraft.svg';
import Report from '@/assets/icons/Report.svg';
import ReportData from '@/assets/icons/ReportData.svg';
import Task from '@/assets/icons/Task.svg';
import LicenseThirdParty from '@/assets/icons/LicenseThirdParty.svg';
import ImageIcon from '@/assets/icons/Image.svg';
import Attachment from '@/assets/icons/Attachment.svg';
import Pen from '@/assets/icons/Pen.svg';
import PenFountain from '@/assets/icons/PenFountain.svg';
import Stamp from '@/assets/icons/Stamp.svg';
import Sun from '@/assets/icons/Sun.svg';
import Asleep from '@/assets/icons/Asleep.svg';
import Cloud from '@/assets/icons/Cloud.svg';
import Rain from '@/assets/icons/Rain.svg';
import Snow from '@/assets/icons/Snow.svg';
import Umbrella from '@/assets/icons/Umbrella.svg';
import Favorite from '@/assets/icons/Favorite.svg';
import Flash from '@/assets/icons/Flash.svg';
import Star from '@/assets/icons/Star.svg';
import RainDrop from '@/assets/icons/RainDrop.svg';
import Sprout from '@/assets/icons/Sprout.svg';
import Tree from '@/assets/icons/Tree.svg';
import PalmTree from '@/assets/icons/PalmTree.svg';
import Debug from '@/assets/icons/Debug.svg';
import Bee from '@/assets/icons/Bee.svg';
import Calendar from '@/assets/icons/Calendar.svg';
import Time from '@/assets/icons/Time.svg';
import Microphone from '@/assets/icons/Microphone.svg';
import Headset from '@/assets/icons/Headset.svg';
import Notification from '@/assets/icons/Notification.svg';
import Phone from '@/assets/icons/Phone.svg';
import WifiController from '@/assets/icons/WifiController.svg';
import HealthCross from '@/assets/icons/HealthCross.svg';
import Chat from '@/assets/icons/Chat.svg';
import Bookmark from '@/assets/icons/Bookmark.svg';
import Flag from '@/assets/icons/Flag.svg';
import Locked from '@/assets/icons/Locked.svg';
import Unlocked from '@/assets/icons/Unlocked.svg';
import Password from '@/assets/icons/Password.svg';
import Idea from '@/assets/icons/Idea.svg';
import BatteryHalf from '@/assets/icons/BatteryHalf.svg';
import Plug from '@/assets/icons/Plug.svg';

export default {
  name: 'SchemaFormFieldRequestsCategoryIcons',

  components: {
    TextField,
    IconButton,
    Rocket,
    Plane,
    Helicopter,
    TrainProfile,
    SailboatOffshore,
    Harbor,
    Car,
    DeliveryTruck,
    Bicycle,
    GasStation,
    ChargingStation,
    Earth,
    Location,
    Enterprise,
    Building,
    Home,
    Hotel,
    Store,
    ShoppingCart,
    Events,
    UserFollow,
    UserProfileAlt,
    Accessibility,
    Finance,
    ToolBox,
    Wallet,
    Money,
    Purchase,
    Receipt,
    Scales,
    PiggyBank,
    Currency,
    CurrencyBaht,
    CurrencyDollar,
    CurrencyEuro,
    CurrencyRuble,
    Percentage,
    Bottles01,
    Bar,
    Drink01,
    FruitBowl,
    NoodleBowl,
    Apple,
    Strawberry,
    Fish,
    Restaurant,
    ServiceDesk,
    Cafe,
    Cookie,
    Basketball,
    Gamification,
    ColorPalette,
    Theater,
    Music,
    GameConsole,
    Model,
    SprayPaint,
    Camera,
    Gift,
    Trophy,
    CalculationAlt,
    Cut,
    PaintBrush,
    TrashCan,
    Chemistry,
    HangingProtocol,
    Binoculars,
    Bullhorn,
    Calibrate,
    Microscope,
    Stethoscope,
    ToolKit,
    Tools,
    ToolsAlt,
    Clean,
    Box,
    Email,
    Folder,
    DocumentBlank,
    DocumentMultiple01,
    ResultDraft,
    Report,
    ReportData,
    Task,
    LicenseThirdParty,
    ImageIcon,
    Attachment,
    Pen,
    PenFountain,
    Stamp,
    Sun,
    Asleep,
    Cloud,
    Rain,
    Snow,
    Umbrella,
    Favorite,
    Flash,
    Star,
    RainDrop,
    Sprout,
    Tree,
    PalmTree,
    Debug,
    Bee,
    Calendar,
    Time,
    Microphone,
    Headset,
    Notification,
    Phone,
    WifiController,
    HealthCross,
    Chat,
    Bookmark,
    Flag,
    Locked,
    Unlocked,
    Password,
    Idea,
    BatteryHalf,
    Plug,
  },

  inheritAttrs: false,

  props: {
    value: {
      type: [Object, String],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    isRequiredField: {
      type: Boolean,
      default: false,
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Array,
      default: () => [],
    },
    hiddenSearch: { type: Boolean, default: false },
    placeholderSearch: { type: String, default: 'label.search_icons' },
  },
  data() {
    return {
      search: '',
      icons: [
        {
          name: 'icRocket',
          icon: Rocket,
          title: 'Rocket',
        },
        {
          name: 'icPlane',
          icon: Plane,
          title: 'Plane',
        },
        {
          name: 'icHelicopter',
          icon: Helicopter,
          title: 'Helicopter',
        },
        {
          name: 'icTrainProfile',
          icon: TrainProfile,
          title: 'Train Profile',
        },
        {
          name: 'icSailboatOffshore',
          icon: SailboatOffshore,
          title: 'Sailboat Offshore',
        },
        {
          name: 'icHarbor',
          icon: Harbor,
          title: 'Harbor',
        },
        {
          name: 'icCar',
          icon: Car,
          title: 'Car',
        },
        {
          name: 'icDeliveryTruck',
          icon: DeliveryTruck,
          title: 'Delivery Truck',
        },
        {
          name: 'icBicycle',
          icon: Bicycle,
          title: 'Bicycle',
        },
        {
          name: 'icGasStation',
          icon: GasStation,
          title: 'Gas Station',
        },
        {
          name: 'icChargingStation',
          icon: ChargingStation,
          title: 'Charging Station',
        },
        {
          name: 'icEarth',
          icon: Earth,
          title: 'Earth',
        },
        {
          name: 'icLocation',
          icon: Location,
          title: 'Location',
        },
        {
          name: 'icEnterprise',
          icon: Enterprise,
          title: 'Enterprise',
        },
        {
          name: 'icBuilding',
          icon: Building,
          title: 'Building',
        },
        {
          name: 'icHome',
          icon: Home,
          title: 'Home',
        },
        {
          name: 'icHotel',
          icon: Hotel,
          title: 'Hotel',
        },
        {
          name: 'icStore',
          icon: Store,
          title: 'Store',
        },
        {
          name: 'icShoppingCart',
          icon: ShoppingCart,
          title: 'Shopping Cart',
        },
        {
          name: 'icEvents',
          icon: Events,
          title: 'Events',
        },
        {
          name: 'icUserFollow',
          icon: UserFollow,
          title: 'User Follow',
        },
        {
          name: 'icUserProfileAlt',
          icon: UserProfileAlt,
          title: 'User Profile Alt',
        },
        {
          name: 'icAccessibility',
          icon: Accessibility,
          title: 'Accessibility',
        },
        {
          name: 'icFinance',
          icon: Finance,
          title: 'Finance',
        },
        {
          name: 'icToolBox',
          icon: ToolBox,
          title: 'Tool Box',
        },
        {
          name: 'icWallet',
          icon: Wallet,
          title: 'Wallet',
        },
        {
          name: 'icMoney',
          icon: Money,
          title: 'Money',
        },
        {
          name: 'icPurchase',
          icon: Purchase,
          title: 'Purchase',
        },
        {
          name: 'icReceipt',
          icon: Receipt,
          title: 'Receipt',
        },
        {
          name: 'icScales',
          icon: Scales,
          title: 'Scales',
        },
        {
          name: 'icPiggyBank',
          icon: PiggyBank,
          title: 'Piggy Bank',
        },
        {
          name: 'icCurrency',
          icon: Currency,
          title: 'Currency',
        },
        {
          name: 'icCurrencyBaht',
          icon: CurrencyBaht,
          title: 'Currency Baht',
        },
        {
          name: 'icCurrencyDollar',
          icon: CurrencyDollar,
          title: 'Currency Dollar',
        },
        {
          name: 'icCurrencyEuro',
          icon: CurrencyEuro,
          title: 'Currency Euro',
        },
        {
          name: 'icCurrencyRuble',
          icon: CurrencyRuble,
          title: 'Currency Ruble',
        },
        {
          name: 'icPercentage',
          icon: Percentage,
          title: 'Percentage',
        },
        {
          name: 'icBottles01',
          icon: Bottles01,
          title: 'Bottles 01',
        },
        {
          name: 'icBar',
          icon: Bar,
          title: 'Bar',
        },
        {
          name: 'icDrink01',
          icon: Drink01,
          title: 'Drink 01',
        },
        {
          name: 'icFruitBowl',
          icon: FruitBowl,
          title: 'Fruit Bowl',
        },
        {
          name: 'icNoodleBowl',
          icon: NoodleBowl,
          title: 'Noodle Bowl',
        },
        {
          name: 'icApple',
          icon: Apple,
          title: 'Apple',
        },
        {
          name: 'icStrawberry',
          icon: Strawberry,
          title: 'Strawberry',
        },
        {
          name: 'icFish',
          icon: Fish,
          title: 'Fish',
        },
        {
          name: 'icRestaurant',
          icon: Restaurant,
          title: 'Restaurant',
        },
        {
          name: 'icServiceDesk',
          icon: ServiceDesk,
          title: 'Service Desk',
        },
        {
          name: 'icCafe',
          icon: Cafe,
          title: 'Cafe',
        },
        {
          name: 'icCookie',
          icon: Cookie,
          title: 'Cookie',
        },
        {
          name: 'icBasketball',
          icon: Basketball,
          title: 'Basketball',
        },
        {
          name: 'icGamification',
          icon: Gamification,
          title: 'Gamification',
        },
        {
          name: 'icColorPalette',
          icon: ColorPalette,
          title: 'Color Palette',
        },
        {
          name: 'icTheater',
          icon: Theater,
          title: 'Theater',
        },
        {
          name: 'icMusic',
          icon: Music,
          title: 'Music',
        },
        {
          name: 'icGameConsole',
          icon: GameConsole,
          title: 'Game Console',
        },
        {
          name: 'icModel',
          icon: Model,
          title: 'Model',
        },
        {
          name: 'icSprayPaint',
          icon: SprayPaint,
          title: 'Spray Paint',
        },
        {
          name: 'icCamera',
          icon: Camera,
          title: 'Camera',
        },
        {
          name: 'icGift',
          icon: Gift,
          title: 'Gift',
        },
        {
          name: 'icTrophy',
          icon: Trophy,
          title: 'Trophy',
        },
        {
          name: 'icCalculationAlt',
          icon: CalculationAlt,
          title: 'Calculation Alt',
        },
        {
          name: 'icCut',
          icon: Cut,
          title: 'Cut',
        },
        {
          name: 'icPaintBrush',
          icon: PaintBrush,
          title: 'Paint Brush',
        },
        {
          name: 'icTrashCan',
          icon: TrashCan,
          title: 'Trash Can',
        },
        {
          name: 'icChemistry',
          icon: Chemistry,
          title: 'Chemistry',
        },
        {
          name: 'icHangingProtocol',
          icon: HangingProtocol,
          title: 'Hanging Protocol',
        },
        {
          name: 'icBinoculars',
          icon: Binoculars,
          title: 'Binoculars',
        },
        {
          name: 'icBullhorn',
          icon: Bullhorn,
          title: 'Bullhorn',
        },
        {
          name: 'icCalibrate',
          icon: Calibrate,
          title: 'Calibrate',
        },
        {
          name: 'icMicroscope',
          icon: Microscope,
          title: 'Microscope',
        },
        {
          name: 'icStethoscope',
          icon: Stethoscope,
          title: 'Stethoscope',
        },
        {
          name: 'icToolKit',
          icon: ToolKit,
          title: 'Tool Kit',
        },
        {
          name: 'icTools',
          icon: Tools,
          title: 'Tools',
        },
        {
          name: 'icToolsAlt',
          icon: ToolsAlt,
          title: 'Tools Alt',
        },
        {
          name: 'icClean',
          icon: Clean,
          title: 'Clean',
        },
        {
          name: 'icBox',
          icon: Box,
          title: 'Box',
        },
        {
          name: 'icEmail',
          icon: Email,
          title: 'Email',
        },
        {
          name: 'icFolder',
          icon: Folder,
          title: 'Folder',
        },
        {
          name: 'icDocumentBlank',
          icon: DocumentBlank,
          title: 'Document Blank',
        },
        {
          name: 'icDocumentMultiple01',
          icon: DocumentMultiple01,
          title: 'Document Multiple 01',
        },
        {
          name: 'icResultDraft',
          icon: ResultDraft,
          title: 'Result Draft',
        },
        {
          name: 'icReport',
          icon: Report,
          title: 'Report',
        },
        {
          name: 'icReportData',
          icon: ReportData,
          title: 'Report Data',
        },
        {
          name: 'icTask',
          icon: Task,
          title: 'Task',
        },
        {
          name: 'icLicenseThirdParty',
          icon: LicenseThirdParty,
          title: 'License Third Party',
        },
        {
          name: 'icImage',
          icon: ImageIcon,
          title: 'Image',
        },
        {
          name: 'icAttachment',
          icon: Attachment,
          title: 'Attachment',
        },
        {
          name: 'icPen',
          icon: Pen,
          title: 'Pen',
        },
        {
          name: 'icPenFountain',
          icon: PenFountain,
          title: 'Pen Fountain',
        },
        {
          name: 'icStamp',
          icon: Stamp,
          title: 'Stamp',
        },
        {
          name: 'icSun',
          icon: Sun,
          title: 'Sun',
        },
        {
          name: 'icAsleep',
          icon: Asleep,
          title: 'Asleep',
        },
        {
          name: 'icCloud',
          icon: Cloud,
          title: 'Cloud',
        },
        {
          name: 'icRain',
          icon: Rain,
          title: 'Rain',
        },
        {
          name: 'icSnow',
          icon: Snow,
          title: 'Snow',
        },
        {
          name: 'icUmbrella',
          icon: Umbrella,
          title: 'Umbrella',
        },
        {
          name: 'icFavorite',
          icon: Favorite,
          title: 'Favorite',
        },
        {
          name: 'icFlash',
          icon: Flash,
          title: 'Flash',
        },
        {
          name: 'icStar',
          icon: Star,
          title: 'Star',
        },
        {
          name: 'icRainDrop',
          icon: RainDrop,
          title: 'Rain Drop',
        },
        {
          name: 'icSprout',
          icon: Sprout,
          title: 'Sprout',
        },
        {
          name: 'icTree',
          icon: Tree,
          title: 'Tree',
        },
        {
          name: 'icPalmTree',
          icon: PalmTree,
          title: 'Palm Tree',
        },
        {
          name: 'icDebug',
          icon: Debug,
          title: 'Debug',
        },
        {
          name: 'icBee',
          icon: Bee,
          title: 'Bee',
        },
        {
          name: 'icCalendar',
          icon: Calendar,
          title: 'Calendar',
        },
        {
          name: 'icTime',
          icon: Time,
          title: 'Time',
        },
        {
          name: 'icMicrophone',
          icon: Microphone,
          title: 'Microphone',
        },
        {
          name: 'icHeadset',
          icon: Headset,
          title: 'Headset',
        },
        {
          name: 'icNotification',
          icon: Notification,
          title: 'Notification',
        },
        {
          name: 'icPhone',
          icon: Phone,
          title: 'Phone',
        },
        {
          name: 'icWifiController',
          icon: WifiController,
          title: 'Wifi Controller',
        },
        {
          name: 'icHealthCross',
          icon: HealthCross,
          title: 'Health Cross',
        },
        {
          name: 'icChat',
          icon: Chat,
          title: 'Chat',
        },
        {
          name: 'icBookmark',
          icon: Bookmark,
          title: 'Bookmark',
        },
        {
          name: 'icFlag',
          icon: Flag,
          title: 'Flag',
        },
        {
          name: 'icLocked',
          icon: Locked,
          title: 'Locked',
        },
        {
          name: 'icUnlocked',
          icon: Unlocked,
          title: 'Unlocked',
        },
        {
          name: 'icPassword',
          icon: Password,
          title: 'Password',
        },
        {
          name: 'icIdea',
          icon: Idea,
          title: 'Idea',
        },
        {
          name: 'icBatteryHalf',
          icon: BatteryHalf,
          title: 'Battery Half',
        },
        {
          name: 'icPlug',
          icon: Plug,
          title: 'Plug',
        },
      ],
      filtredIcons: [],
    };
  },

  computed: {
    valueModel: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),
    selectedIcon() {
      return this.icons.find(({ name }) => name === this.value) || {};
    },
  },

  watch: {
    search() {
      this.debounceSearch();
    },
    payload(newPayload, oldPayload) {
      if (!isEqual(newPayload, oldPayload)) {
        this.$refs.multiselect?.debouncedFetchItems();
      }
    },
    valueModel() {
      this.$emit('validate');
    },
  },

  mounted() {
    this.filtredIcons = [...this.icons];
  },

  methods: {
    queryItems(payload) {
      if (payload.search === '') {
        return fetchRequestsCategoriesIcons({ ...payload }).then(({ results }) => results);
      }
      return fetchRequestsCategoriesIcons({ ...payload, ...this.payload }).then(({ results }) => results);
    },

    debounceSearch: debounce(function() {
      this.searchIcons();
    }, 300),

    searchIcons() {
      this.filtredIcons = this.icons.filter(({ title }) => title.toLowerCase().includes(this.search.toLowerCase()));
    },

    selectIcon(icon) {
      // setPropertyValue({ propPath, value: $event })
      this.valueModel = icon.name;
    },

    deleteIcon() {
      // setPropertyValue({ propPath, value: $event })
      this.valueModel = null;
    },
  },

  compareById,
};
</script>

<style lang="scss">
.icon-select {
  &__search-field {
    position: sticky;
    background: $--white-color-0;
    top: 0;
    z-index: 1;
  }
}
</style>
