import { monthsTanslations } from '@/constants/translations';
import i18n from '@/plugins/i18n';
import * as locales from 'date-fns/locale';
import { format as _format } from 'date-fns';

/**
 * @param {number | Date} date
 * @param {string} stringFormat
 * @param {Object} options
 */
// eslint-disable-next-line import/prefer-default-export
export function format(date, stringFormat, options) {
  return _format(date, stringFormat, {
    locale: locales[i18n.locale.toLowerCase()],
    ...options,
  });
}

// eslint-disable-next-line import/prefer-default-export
export function translateDate(date) {
  if (!date) {
    return '';
  }
  const splittedDate = date.split(' ');
  const month = monthsTanslations[splittedDate[1] - 1];
  splittedDate[1] = i18n.t(month);
  return splittedDate.join(' ');
}

export function translateShortDate(date) {
  const splittedDate = date.split('-');
  const month = monthsTanslations[splittedDate[0] - 1];
  splittedDate[0] = i18n.t(month);
  return splittedDate.join(' ');
}

export function translateInvoiceDate(isOneTime, date, dateEnd) {
  if (!isOneTime && date) {
    const splittedDate = date.split(' ');

    const month = monthsTanslations[splittedDate[1] - 1];
    splittedDate[1] = i18n.t(month);

    splittedDate.shift();
    return splittedDate.join(' ');
  }

  return dateEnd ? `${translateDate(date)} - ${translateDate(dateEnd)}` : translateDate(date);
}

export function roundMinutes(minutes, interval) {
  return Math.ceil(minutes / interval) * interval;
}

export function dateDoubleFormat(dates) {
  if (dates.split(' - ').length < 2) return null;
  return dates
    .split(' - ')
    .map(date => translateDate(format(new Date(date), 'd L yyyy')))
    .join(' - ');
}
