/* eslint-disable prefer-destructuring */
import { format, addDays } from 'date-fns';
import { extractParamsFromMultiSelectObject, checkEmptyParams } from '@/utils/many';
import * as typesClient from '@/constants/typesClients';
import i18n from '@/plugins/i18n';

export function periodSerializer(value) {
  return value === 'month' ? 'month' : 'date';
}

export function isTenantSerializer(value) {
  const TENANT = 'tenant';

  if (value?.toLowerCase() === TENANT) {
    return true;
  }

  return false;
}

export function isTodaySerializer(value) {
  const nowDate = format(new Date(), 'yyyy-MM-dd');

  return nowDate === value;
}

export function nextDaySerializer(value) {
  const date = new Date(value);
  const nextDayDate = addDays(date, 1);

  return format(nextDayDate, 'yyyy-MM-dd');
}

export function extractParamsFromMultiSelectObjectSerializer(value) {
  if (checkEmptyParams(value)) return undefined;
  return extractParamsFromMultiSelectObject(value);
}

export function notAvailable(value) {
  return value !== 'available';
}

export function isRented(value) {
  return value === 'rented';
}

export function capitalizeFirstLetterSerializer(string) {
  if (string === 'rented_partially') {
    return 'Rooms rented';
  }
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

export function normalizeProjectDetailed(project) {
  const initialData = { ...project };

  if (project.notificationSettings) {
    const at = Number(project.notificationSettings.at.split(':')[0]);

    initialData.at = at > 11 ? `${Math.abs(at - 12)} PM` : `${at} AM`;
    const dayWord = project.notificationSettings.repeatInterval > 1 ? 'days' : 'day';
    initialData.repeatInterval = `${project.notificationSettings.repeatInterval} ${dayWord}`;
    initialData.repeatCount = project.notificationSettings.repeatCount;

    let recipients = '';

    switch (project.notificationSettings.recipients) {
      case 'long_term_tenants':
        recipients = i18n.t('notification.long_term_tenants');
        break;
      case 'users':
        recipients = i18n.t('notification.users');
        break;
      case 'all':
        recipients = i18n.t('notification.long_term_tenants_and_users');
        break;
      default:
        break;
    }

    initialData.recipients = recipients;
  }

  initialData.roles = project.roles.map(role => ({
    id: role.id,
    name: role.name,
    employees: {
      all: role.isAllEmployees,
      include: role.employees,
    },
  }));

  return initialData;
}

export function normalizeRequestDetailed(request) {
  const initialData = { ...request };

  initialData.category = request.category.title;
  initialData.type = request.category.categoryType.name;

  initialData.pendingRoles = request.roles?.pending;
  initialData.inProgressRoles = request.roles?.inProgress;
  initialData.completedRoles = request.roles?.completed;

  initialData.paymentRequired = request.paymentRequired
    ? {
        amount: request.paymentAmount,
        contractor: request.contractor,
      }
    : false;

  initialData.paymentAmount = request.paymentAmount || undefined;

  return initialData;
}

export function normalizeUnit(unit, isView) {
  const initialData = { ...unit, ...unit.landlordInfo };

  if (isView) {
    initialData.address = unit.address ? `${unit.address}, ${unit.addressNumber}` : '—';
  }

  unit.clients.forEach(client => {
    if (typesClient.RENTAL_CLIENT_TYPES.includes(client.clientType)) {
      initialData.rentalPeriodDate = `${client.rentalPeriodStartDate} - ${client.rentalPeriodEndDate}`;
      initialData.tenant = { ...client, id: client.client };
    } else {
      initialData.salesContractDate = client.salesContractDate;
      initialData.owner = { ...client, id: client.client };
    }
  });

  return initialData;
}

export function normalizeRoom(room) {
  const initialData = { ...room };

  room.clients.forEach(client => {
    if (typesClient.RENTAL_CLIENT_TYPES.includes(client.clientType)) {
      initialData.rentalPeriodDate = `${client.rentalPeriodStartDate} - ${client.rentalPeriodEndDate}`;
      initialData.tenant = { ...client, id: client.client };
    } else {
      initialData.salesContractDate = client.salesContractDate;
      initialData.owner = { ...client, id: client.client };
    }
  });

  return initialData;
}

export function normalizeProject(project) {
  const initialData = { ...project };
  // @todo Костыль
  const times = [
    { name: '12 AM', value: '00:00' },
    { name: '1 AM', value: '01:00' },
    { name: '2 AM', value: '02:00' },
    { name: '3 AM', value: '03:00' },
    { name: '4 AM', value: '04:00' },
    { name: '5 AM', value: '05:00' },
    { name: '6 AM', value: '06:00' },
    { name: '7 AM', value: '07:00' },
    { name: '8 AM', value: '08:00' },
    { name: '9 AM', value: '09:00' },
    { name: '10 AM', value: '10:00' },
    { name: '11 AM', value: '11:00' },
    { name: '12 PM', value: '12:00' },
    { name: '1 PM', value: '13:00' },
    { name: '2 PM', value: '14:00' },
    { name: '3 PM', value: '15:00' },
    { name: '4 PM', value: '16:00' },
    { name: '5 PM', value: '17:00' },
    { name: '6 PM', value: '18:00' },
    { name: '7 PM', value: '19:00' },
    { name: '8 PM', value: '20:00' },
    { name: '9 PM', value: '21:00' },
    { name: '10 PM', value: '22:00' },
    { name: '11 PM', value: '23:00' },
  ];

  const days = [
    { name: '1 day', value: 1 },
    { name: '2 days', value: 2 },
    { name: '3 days', value: 3 },
    { name: '4 days', value: 4 },
    { name: '5 days', value: 5 },
    { name: '6 days', value: 6 },
    { name: '7 days', value: 7 },
    { name: '8 days', value: 8 },
    { name: '9 days', value: 9 },
    { name: '10 days', value: 10 },
  ];

  if (initialData.notificationSettings) {
    initialData.at = times.filter(
      time => time.value.split(':')[0] === initialData.notificationSettings.at.split(':')[0]
    )[0];
    initialData.repeatCount = initialData.notificationSettings.repeatCount;
    initialData.repeatInterval = days.filter(day => day.value === initialData.notificationSettings.repeatInterval)[0];
    initialData.recipients = initialData.notificationSettings.recipients;
    initialData.employees = initialData.notificationSettings.employees.length
      ? {
          all: false,
          exclude: [],
          include: initialData.notificationSettings.employees,
        }
      : {
          all: false,
          exclude: [],
          include: [],
        };
  } else {
    initialData.at = { name: '06 PM', value: '18:00' };
    initialData.repeatCount = 1;
    initialData.repeatInterval = { name: '5 days', value: 5 };
    initialData.recipients = '';
    initialData.employees = {
      all: false,
      exclude: [],
      include: [],
    };
  }

  initialData.notificationSettings = !!initialData.notificationSettings;

  initialData.coverImage = initialData.coverImage ? [{ id: initialData.coverImage, url: initialData.coverImage }] : [];

  initialData.roles = project.roles.map(role => {
    return {
      role: {
        id: role.id,
        name: role.name,
      },
      employees: {
        all: role.isAllEmployees,
        include: role.employees,
        exclude: [],
      },
    };
  });

  return initialData;
}

export function normalizeEmployee(employee) {
  const initialData = { ...employee };
  initialData.roles = employee.roles.map(role => {
    return {
      role: {
        id: role.id,
        name: role.name,
      },
      projects: {
        all: role.isAllProjects,
        include: role.projects,
        exclude: [],
      },
    };
  });

  return initialData;
}
